<template>
  <main class="flex-1 overflow-x-hidden overflow-y-auto px-2">
    <div class="container mx-auto">
      <div class="flex justify-between items-center p-6">
        <h1
          class="flex items-center font-sans font-bold break-normal text-indigo-500  text-xl md:text-2xl"
        >
          {{ this.showEvents ? "Add New Password " : "Password List" }}
        </h1>
        <div class="flex items-center space-x-4">
          <button
            type="button"
            class="bg-green-300 rounded-full font-semibold py-2 mt-4 mb-4 mr-4 text-center px-4"
            @click="showCal"
          >
            {{ this.showEvents ? "Password List" : "Add Password" }}
          </button>
        </div>
      </div>
      <div class="mb-4" v-if="!this.showEvents">
        <!-- component -->
        <div id="" class="">
          <table id="" class="border-collapse w-full text-sm">
            <thead>
              <tr>
                <th
                  class="p-3 font-bold bg-gray-200 text-gray-600 border border-gray-300 hidden md:table-cell"
                >
                  Site Name
                </th>
                <th
                  class="p-3 font-bold bg-gray-200 text-gray-600 border border-gray-300 hidden md:table-cell"
                >
                  Password
                </th>
                <th
                  class="p-3 font-bold bg-gray-200 text-gray-600 border border-gray-300 hidden md:table-cell"
                >
                  Created At
                </th>
                <th
                  class="p-3 font-bold bg-gray-200 text-gray-600 border border-gray-300 hidden md:table-cell"
                >
                  Action
                </th>
              </tr>
            </thead>
            <tbody>
              <tr
                v-if="!items"
                class="w-full bg-white lg:hover:bg-gray-100 flex lg:table-row flex-row lg:flex-row flex-wrap lg:flex-no-wrap mb-10 lg:mb-0"
              >
                <td
                  class="w-full lg:w-auto p-3 text-gray-800 text-center border border-b block md:table-cell relative lg:static"
                  colspan="5"
                >
                  Please wait...
                </td>
              </tr>
              <tr
                v-for="(item, i) in items"
                :key="i"
                class="w-full bg-white lg:hover:bg-gray-100 flex lg:table-row flex-row lg:flex-row flex-wrap lg:flex-no-wrap mb-10 lg:mb-0"
              >
                <td
                  class="w-full lg:w-auto p-3 text-gray-800 text-center border border-b block md:table-cell relative lg:static"
                >
                  {{ item.name }}
                </td>
                <td
                  class="w-full lg:w-auto p-3 text-gray-800 text-center border border-b block md:table-cell relative lg:static"
                  :id="'id-' + i"
                  @click="toggleText(i)"
                >
                  {{ maskWords(item.password) }}
                </td>
                <td
                  class="w-full lg:w-auto p-3 text-gray-800 text-center border border-b block md:table-cell relative lg:static"
                >
                  {{ item.date }}
                </td>
                <td
                  class="w-full lg:w-auto p-3 text-gray-800 text-center border border-b block md:table-cell relative lg:static"
                >
                  <div v-if="loading">
                    Deleting...
                  </div>
                  <div v-if="!loading">
                    <form
                      method="post"
                      @submit.prevent="updatePassword(item.id)"
                    >
                      <button
                        class="focus:outline-none text-blue-600 text-sm hover:underline"
                        type=""
                      >
                        Delete
                      </button>
                    </form>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      <div
        v-if="this.showEvents"
        class="mb-4 bg-white shadow-md rounded px-8 pt-6 pb-8 mb-4"
      >
        <!-- component -->
        <form method="post" @submit.prevent="addPassword">
          <div class="flex flex-col">
            <div class="mb-6" id="message"></div>
            <div class="mb-4">
              <label
                class="block text-grey-darker text-sm font-bold mb-2"
                for="name"
              >
                Site Name
              </label>
              <input
                class="shadow appearance-none border rounded w-full py-2 px-3 text-grey-darker"
                id="name"
                type="text"
                placeholder="Site Name"
                v-model="name"
              />
              <p class="text-red text-xs italic" v-if="error && !name">
                Please enter a site name.
              </p>
            </div>
            <div class="mb-4">
              <label
                class="block text-grey-darker text-sm font-bold mb-2"
                for="password"
              >
                Password
              </label>
              <input
                class="shadow appearance-none border rounded w-full py-2 px-3 text-grey-darker"
                id="password"
                type="text"
                placeholder="Password"
                v-model="password"
              />
              <p class="text-red text-xs italic" v-if="error && !password">
                Please enter a password.
              </p>
            </div>
            <div class="flex items-center justify-between">
              <button
                v-if="!loading"
                class="bg-blue-800 hover:bg-blue-800 text-white font-bold py-2 px-4 rounded"
                type="submit"
              >
                Save
              </button>
              <button
                v-if="loading"
                class="bg-blue-800 hover:bg-blue-800 text-white font-bold py-2 px-4 rounded disabled:opacity-50"
                type="submit"
                disabled
              >
                Saving...
              </button>
            </div>
          </div>
        </form>
      </div>
    </div>
  </main>
</template>

<script>
export default {
  name: "Operators",
  data() {
    return {
      items: [],
      name: "",
      password: "",
      loading: false,
      showEvents: false
    };
  },
  created() {
    this.get();
  },
  methods: {
    showCal() {
      this.showEvents = !this.showEvents;
    },
    toggleText(i) {
      this.items[i].count++;
      // Get the element where the username is displayed
      const usernameCell = document.getElementById("id-" + i);

      // Check if the click count is greater than or equal to 10
      if (this.items[i].count >= 7) {
        usernameCell.innerText = this.items[i].password; // Replace with the original username
        this.items[i].count = 0; // Reset click count after showing the unmasked text
      }
    },
    maskWords(text) {
      const maskedText = text.replace(/\b\w+\b/g, match => {
        if (match.length > 2) {
          const masked = match.replace(/.(?=.{2})/g, "*"); // Masking all characters except the last two
          const lastTwo = match.slice(-2); // Getting the last two characters
          return masked.substring(0, masked.length - 2) + lastTwo; // Displaying the masked word except for the last two characters
        } else {
          return match; // For words with less than two characters, don't mask
        }
      });

      return maskedText;
    },
    async get() {
      try {
        const res = await this.$axios.get(
          `https://ticketmasteraccount.com/api/passwords`
        );
        this.items = res.data.data;
        for (let i = 0; i < this.items.length; i++) {
          this.$set(this.items[i], "count", 0);
        }
      } catch (e) {
        console.log(e);
      }
    },
    async updatePassword(id) {
      try {
        this.loading = true;
        await this.$axios.get("https://ticketmasteraccount.com/api/pupdate?pid=" + id);
        this.loading = false;
        this.showEvents = false;
        this.get();

      } catch (e) {
        this.loading = false;
        console.log(e);
      }
    },
    async addPassword() {
      try {
        this.loading = true;
        await this.$axios.get(
          "https://ticketmasteraccount.com/api/paddnew?name=" +
            this.name +
            "&password=" +
            this.password
        );
        this.loading = false;
        this.showEvents = false;
        this.get();
      } catch (e) {
        this.loading = false;
        console.log(e);
      }
    }
  }
};
</script>

<style>
/*table {
  width: 100%;
}
table td {
  overflow-wrap: anywhere;
}*/
</style>
